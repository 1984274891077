import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input, Button, Space, Table, Spin, message, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import styles from './assetRename.module.css';
import { SERVER } from '../config';
import { token, orgId } from '../constants';

const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name, { file_id }) =>
      file_id ? (
        <a
          href={`${SERVER}/crop-view/?fileId=${file_id}&jobName=${name}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {name}
        </a>
      ) : (
        name
      ),
  },
  {
    title: 'Job Id',
    dataIndex: 'job_id',
    key: 'job_id',
  },
  {
    title: 'User',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Progress',
    dataIndex: 'progress',
    key: 'progress',
  },
  {
    title: 'Trigger Date',
    dataIndex: 'start_date',
    key: 'start_date',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: 'Uploaded',
    dataIndex: 's7upload',
    key: 's7upload',
  },
  {
    title: 'Resubmit',
    dataIndex: 'resubmit',
    key: 'resubmit',
    render: (upload, { onResubmit }) => (
      <button onClick={onResubmit}>Resubmit</button>
    ),
  },
  {
    title: 'Upload to Scene7',
    dataIndex: 'upload',
    key: 'upload',
    render: (upload, { file_id, onClick }) =>
      file_id ? <button onClick={onClick}>Upload</button> : '',
  },
];

const JobsHistory = ({ reload }) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState(null);
  const searchInput = useRef(null);

  const fetchResults = useCallback(() => {
    fetch(
      `${SERVER}/api/jobsHistory?token=${token}&orgId=${orgId}&page=${page}&perPage=${perPage}`
    )
      .then((res) => res.json())
      .then((history) => {
        const data = history.results
          .map((i) => i.value)
          .filter((i) => i.name && i.status);

        setResults(data);
        setTotal(history.count);
      });
  }, []);

  useEffect(() => {
    fetchResults();
  }, [fetchResults]);

  useEffect(() => {
    if (reload) {
      fetchResults();
    }
  }, [reload, fetchResults]);
  const handleSearch = (selectedKeys: string[], confirm) => {
    console.log(selectedKeys);
    confirm();
    setKeyword(selectedKeys[0]);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setKeyword('');
  };
  const onPaginationChagne = async () => {
    let url = `${SERVER}/api/jobsHistory?token=${token}&orgId=${orgId}&page=${page}&perPage=${perPage}`;
    if (keyword) {
      url += `&keyword=${keyword}`;
    }
    const res = await fetch(url);
    const history = await res.json();

    const data = history.results
      .map((i) => i.value)
      .filter((i) => i.name && i.status);

    setResults(data);
    setTotal(history.count);
  };
  useEffect(() => {
    onPaginationChagne();
  }, [page, perPage, keyword]);

  COLUMNS[0] = {
    ...COLUMNS[0],
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  };
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPerPage(pagination.pageSize);
  };
  const onScene7Upload = (jobId) => {
    console.log(jobId);
    setIsLoading(true);
    fetch(`${SERVER}/api/scene7Upload`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        newCodes: [],
        codes: [],
        jobs: [jobId],
        token,
        orgId,
      }),
    }).then(() => {
      setIsLoading(false);
      message.success('Sucessfully submitted scene7 upload job');
    });
  };

  const onResubmit = (jobId) => {
    fetch(`${SERVER}/api/queryImages`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        selected: [jobId],
        token,
        orgId,
        resubmit: true,
      }),
    }).then((res) => {
      if (res.ok) {
        message.success('Sucessfully re-submitted job');
      }
    });
  };

  let dataSource = results.map((i) => {
    i.onClick = () => {
      if (
        window.confirm(`Do you want upload ${i.name} job result to Scene7?`)
      ) {
        onScene7Upload(i.job_id);
      }
    };

    i.onResubmit = () => {
      if (window.confirm(`Do you want resubmit job ${i.name}?`)) {
        onResubmit(i.job_id);
      }
    };

    i.start_date = moment(new Date(i.start_date), 'DD MM YYYY hh:mm:ss').format(
      'MMM DD YYYY, h:mm:ss a'
    );
    i.progress = i.total_images
      ? `${i.processed_images || 0}/${i.total_images}`
      : `N/A`;
    return i;
  });
  console.log(dataSource);
  return (
    <div className={styles.historyRoot}>
      {isLoading && (
        <div>
          <div className={styles.historyLoaderBG}></div>
          <div className={styles.historyLoader}>
            <Spin spinning={isLoading} />
          </div>
        </div>
      )}

      <div className={styles.header}>
        <span>White Sweep Job History</span>
      </div>

      <Table
        columns={COLUMNS}
        dataSource={dataSource}
        pagination={{
          defaultCurrent: 1,
          pageSize: perPage,
          page: page,
          total: total,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default JobsHistory;
